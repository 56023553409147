
.fc-unthemed {
  .fc-content,
  .fc-divider,
  .fc-list-heading td,
  .fc-list-view,
  .fc-popover,
  .fc-row,
  tbody,
  td,
  th,
  thead {
    border-color: $border-color;
  }

  .fc-toolbar {
    h2 {
      font-size: $h2-font-size;
      margin: 0;
    }
  }

  .fc-button {
    @include button-variant(#ffffff, $border-color, $text-muted);
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    @include transition($btn-transition);
  }

  .fc-widget-header {
    border: 0;
  }

  .fc-day-header {
    padding-bottom: .25rem;
    font-weight: $font-weight-bold;
  }

  &.card-calendar {
    .fc-day-grid-container {
      border-bottom: 0;
      border-left: 0;

      td:last-child {
        //border-right: 0;
      }

      td:first-child {
        //border-left: 0;
      }
    }
  }

  .fc-time {
    font-weight: $font-weight-bold;
  }

  .fc-event {
    border-color: rgba(0, 0, 0, .05);
    color: $primary;
    background-color: theme-color-lighter($primary);
    padding: 0 .25rem;
  }
}
