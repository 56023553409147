.footer {
  border-top: 1px solid $footer-border-color;
  background-color: $footer-bg;
  padding: 1rem 0;
  color: $text-muted;

  margin-bottom: (-$content-padding-y);
  margin-top: $content-padding-y;
}

.footer-transparent {
  margin-top: 0;
  background-color: transparent;
  border-top: 0;
}