.list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.list-item-actions {
  margin-left: auto;
}

.list-bordered {
  .list-item {
    border-top: 1px solid $border-color;
    margin-top: -1px;

    &:first-child {
      border-top: none;
    }
  }
}

.list-hoverable {
  .list-item-actions {
    opacity: 0;
    transition: .3s opacity;
  }

  .list-item:hover .list-item-actions,
  .list-item-actions.show {
    opacity: 1;
  }
}

.list-row {
  @extend .list-bordered;

  .list-item {
    flex-direction: row;
    align-items: center;
    padding: .5rem ($card-spacer-x / 2);

    > * {
      padding-left: ($card-spacer-x / 2);
      padding-right: ($card-spacer-x / 2);
    }
  }
}


.list-timeline {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    position: relative;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-timeline-time {
  float: right;
  margin-left: 1rem;
  color: $text-muted;
}

.list-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: #ffffff;
  text-align: center;
  background: $text-muted;
  border-radius: 50%;

  .icon {
    width: 1rem;
    height: 1rem;
  }
}

.list-timeline-title {
  margin: 0;
  font-weight: $font-weight-bold;
}

.list-timeline-content {
  margin-left: 3.5rem;
}


@media screen and (min-width: 768px) {
  .list-timeline:not(.list-timeline-simple) {
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: add(7.5rem, 2px);
      z-index: 1;
      display: block;
      width: 4px;
      content: "";
      background-color: $body-bg;
    }

    & > li {
      z-index: 2;
      min-height: 40px;
    }

    .list-timeline-time {
      position: absolute;
      top: .5rem;
      left: 0;
      width: 5.5rem;
      margin: 0;
      text-align: right;
    }

    .list-timeline-icon {
      top: 0;
      left: 6.5rem;
    }

    .list-timeline-content {
      padding: .625rem 0 0 10rem;
      margin: 0;
    }
  }
}


.list-group-transparent {
  margin: 0 (- $list-group-item-padding-x);

  .list-group-item {
    background: none;
    border: 0;
    border-radius: 3px;

    .icon {
      color: $text-muted;
    }

    &.active {
      font-weight: $font-weight-bold;
      color: inherit;
      background: $active-bg;

      .icon {
        color: inherit;
      }
    }
  }
}


.list-separated-item {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid $border-color;
  }
}

/**
Inline list
 */

.list-inline-dots {
  .list-inline-item + .list-inline-item:before {
      content: '· ';
      margin-right: $list-inline-padding;
  }
}