.chart {
  display: block;
  min-height: 10rem;

  text {
    font-family: inherit;
  }
}

.chart-sm {
  height: 2.5rem;
}

.chart-lg {
  height: 15rem;
}

.chart-square {
  height: 5.75rem;
}

/**
Chart sparkline
 */
.chart-sparkline {
  position: relative;
  width: 4rem;
  height: 2.5rem;
  line-height: 1;
}

.chart-sparkline-square {
  width: 2.5rem;
}

.chart-sparkline-wide {
  width: 6rem;
}

.chart-sparkline-label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $h6-font-size;

  .icon {
    width: 1rem;
    height: 1rem;
  }
}