.modal-content {
  .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    width: $modal-header-height;
    height: $modal-header-height;
    margin: 0;
    padding: 0;
    z-index: 10;
  }
}

.modal-body {
  @include scrollbar;

  .modal-title {
    margin-bottom: 1rem;
  }

  & + & {
    border-top: 1px solid $border-color-light;
  }
}

.modal-header {
  align-items: center;
  min-height: $modal-header-height;
  background: $modal-header-bg;
  padding: 0 $modal-header-height 0 $modal-inner-padding;
}

.modal-title {
  font-size: $h3-font-size;
  font-weight: $headings-font-weight;
  color: $headings-color;
  line-height: (24/18);
}

.modal-footer {
  @if $modal-footer-border-width == 0 {
    padding-top: 0;
  } @else {
    padding-top: .75rem;
  }

  padding-bottom: .75rem;
}

.modal-blur {
  backdrop-filter: blur($modal-backdrop-blur);
}

.modal-full-width {
  max-width: none;
  margin: 0 $modal-dialog-margin;
}