.page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.content {
  padding-top: $content-padding-y;
  padding-bottom: $content-padding-y;
  flex: 1;
  display: flex;
  flex-direction: column;

  >[class*="container"] {
    flex: 1;
  }

  @media print {
    margin: 0 !important;
  }
}

.page-header {
  display: flex;
  align-items: center;
  min-height: 2.25rem;
  margin: 0 0 $card-spacer-y;

  >* {
    flex: 1;
  }
}

.page-pretitle {
  @include subheader;
}

.page-title {
  margin: 0;
  font-size: $h2-font-size;
  font-weight: 400;
  line-height: 1;
  color: inherit;
}
