@mixin caret($direction: down) {
  $selector: 'after';

  @if $direction == 'left' {
    $selector: 'before'
  }

  &:#{$selector} {
    content: "";
    display: inline-block;
    vertical-align: $caret-vertical-align;
    width: $caret-width;
    height: $caret-width;
    border-bottom: 1px solid;
    border-left: 1px solid;
    margin-right: .1em;

    @if $direction != 'left' {
      margin-left: $caret-spacing;
    } @else {
      margin-right: $caret-spacing;
    }

    @if $direction == down {
      transform: rotate(-45deg);
    } @else if $direction == up {
      transform: rotate(135deg);
    } @else if $direction == right {
      transform: rotate(-135deg);
    } @else {
      transform: rotate(45deg);
    }
  }

  @if $direction == 'left' {
    &:after {
      content: none;
    }
  }
}
