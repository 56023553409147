textarea {
  &[cols] {
    height: auto;
  }
}

/**
Form label
 */
.form-label {
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  display: block;
  margin-bottom: .5rem;

  &.required {
    &:after {
      content: "*";
      margin-left: .25rem;
      color: $red;
    }
  }
}

.form-label-description {
  float: right;
  font-weight: $font-weight-normal;
  color: $text-muted;
  line-height: $line-height-base * (100%/$small-font-size);
  font-size: $small-font-size;
}

/**
Form hint
 */
.form-hint {
  display: block;
  font-size: $small-font-size;
  color: $text-muted;
  line-height: $line-height-sm;

  &:last-child {
    margin-bottom: 0;
  }

  & + .form-control {
    margin-top: .25rem;
  }

  .form-label + & {
    margin-top: -.5rem;
  }

  .form-control + &,
  .form-select + & {
    margin-top: .25rem;
  }
}

/**
Form control
 */
.form-control {
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px #ffffff inset;
  }

  &:disabled,
  &.disabled {
    color: $text-muted;
    user-select: none;
  }

  &[size] {
    width: auto;
  }
}

.form-control-light {
  background-color: $gray-100;
  border-color: transparent;
}

.form-control-dark {
  background-color: rgba($black, .1);
  color: $white;
  border-color: transparent;

  &:focus {
    background-color: rgba($black, .1);
    box-shadow: none;
    border-color: rgba($white, .24);
  }

  &::placeholder {
    color: rgba($white, .6);
  }
}

.form-control-rounded {
  border-radius: 10rem;
}

.form-control-flush {
  padding: 0;
  background: none !important;
  border-color: transparent !important;
  resize: none;
  box-shadow: none !important;
  line-height: inherit;
}

.form-footer {
  margin-top: 2rem;
}

.form-fieldset {
  padding: 1rem;
  margin-bottom: 1rem;
  background: $min-black;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

/**
Form help
 */
.form-help {
  display: inline-flex;
  font-weight: $font-weight-bold;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  font-size: .75rem;
  color: $text-muted;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background: $min-white;
  border-radius: 50%;
  transition: .3s background-color, .3s color;

  &:hover,
  &[aria-describedby] {
    color: #ffffff;
    background: $primary;
  }
}

/**
Form switch
 */
.form-check {
  user-select: none;

  &.form-check-highlight .form-check-input:not(:checked) ~ .form-check-label {
    opacity: .64;
  }
}

.form-check-input {
  margin-top: .25rem;

  .form-switch & {
    transition: .3s background-color, .3s background-position;
  }
}

.form-check-label {
  line-height: $line-height-sm;
  padding: .125rem 0;
}

.form-check-description {
  color: $text-muted;
  font-size: $h5-font-size;
  line-height: $line-height-sm;
}

/**
Input group
 */
.input-group-link {
  font-size: $h5-font-size;
}

.input-group-flat {
  &:focus-within {
    box-shadow: $input-focus-box-shadow;
    border-radius: $input-border-radius;

    .form-control,
    .input-group-text {
      border-color: $input-focus-border-color !important;
    }
  }

  .form-control {
    &:focus {
      border-color: $input-border-color;
      box-shadow: none;
    }

    &:not(:last-child) {
      border-right: 0;
    }

    &:not(:first-child) {
      border-left: 0;
    }
  }

  .input-group-text {
    background: transparent;
    transition: $input-transition;

    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}


/**
Upload files
 */
.form-file-button {
  margin-left: 0;
  border-left: 0;
}